<template>
  <div>
    <v-sheet
      class="better-scrollbar"
      :style="{
        height: 'calc(90vh - 100px)',
        'overflow-y': 'auto',
        'overflow-x': 'hidden',
      }"
    >
      <v-container :key="`dark-${isDark}`" fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
              v-model="selectedTime"
              :items="timeRanges"
              dense
              label="Time Range"
              outlined
              item-text="label"
              item-value="value"
              @change="getTpmsMessages"
            />
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-chip
              class="my-2 mr-2"
              color="primary"
              :outlined="!showTemperatureSeries"
              label
              @click="showTemperatureSeries = !showTemperatureSeries"
            >
              <v-icon class="mr-1">mdi-thermometer-high</v-icon>
              Temperature (°C)
            </v-chip>

            <v-chip
              class="my-2"
              color="primary"
              :outlined="!showPressureSeries"
              label
              @click="showPressureSeries = !showPressureSeries"
            >
              <v-icon class="mr-1">mdi-air-filter</v-icon>
              Air pressure (psi)
            </v-chip>
            <div
              v-if="loadingChart"
              cols="12"
              class="loader"
              style=""
            >
              <v-card class="pa-4 primary">
                <v-progress-circular
                  indeterminate
                  color="white"
                />
              </v-card>
            </div>
            <v-card v-if="!loadingChart" style="position: relative; overflow: hidden;" outlined height="65vh">
              <div class="" :style="{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 0.4
              }"
              />
              <apexchart
                :key="`chart-${isDark}-${showTemperatureSeries}-${showPressureSeries}-${selectedTime}`"
                type="line"
                height="100%"
                :options="chartOptions"
                :series="totalSeries"
              />
            </v-card>
          </v-col>
          <!-- <v-col cols="12" class="pa-2">
            <v-chip class="my-2" color="primary" label>
              <v-icon class="mr-1">mdi-air-filter</v-icon>
              Air pressure
            </v-chip>
            <div
              v-if="loadingChart"
              cols="12"
              class="loader"
              style=""
            >
              <v-card class="pa-4 primary">
                <v-progress-circular
                  indeterminate
                  color="white"
                />
              </v-card>
            </div>
            <v-card v-if="!loadingChart" style="position: relative; overflow: hidden;" outlined height="300">
              <div class="" :style="{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 0.4
              }"
              />
              <apexchart
                type="line"
                height="100%"
                :options="pressureChartOptions"
                :series="pressureSeries"
              />
            </v-card>
          </v-col> -->
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
import list from 'GraphQL/queries/tpmsMessages/list.gql'

import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  components: {},
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    unit: {
      type: Object,
      default: () => {}
    },
    currentTab: {
      type: Number,
      default: () => 0
    },
    dialog: {
      required: true,
      type: Boolean
    }
  },

  data () {
    return {
      loadingChart: true,
      currentDay: null,
      showTemperatureSeries: true,
      showPressureSeries: true,
      selectedTime: 'last500',
      colorStops: [
        {
          offset: 0,
          color: '#C62828',
          opacity: 1
        },
        {
          offset: 75,
          color: '#FFEE58',
          opacity: 1
        },
        {
          offset: 85,
          color: '#4CAF50',
          opacity: 1
        }
      ]
    }
  },

  computed: {
    ...mapState(['session', 'width', 'isLoading', 'isDark', 'units']),
    ...mapGetters(['color', 'isMobile']),
    user () { return this.$store.state.session },
    messages () { return this.$store.state.units.tpmsMessagesUnit },
    tyres () {
      if (this.unit?.style === 'TRAILER_12') {
        return [
          {
            name: 'NSRO',
            number: '36'
          },
          {
            name: 'NSMO',
            number: '26'
          },
          {
            name: 'NSFO',
            number: '16'
          },
          {
            name: 'NSRI',
            number: '37'
          },
          {
            name: 'NSMI',
            number: '27'
          },
          {
            name: 'NSFI',
            number: '17'
          },
          {
            name: 'OSRI',
            number: '39'
          },
          {
            name: 'OSMI',
            number: '29'
          },
          {
            name: 'OSFI',
            number: '19'
          },
          {
            name: 'OSRO',
            number: '3a'
          },
          {
            name: 'OSMO',
            number: '2a'
          },
          {
            name: 'OSFO',
            number: '1a'
          }
        ]
      } else {
        return [
          {
            name: 'NSR',
            number: '37'
          },
          {
            name: 'NSM',
            number: '27'
          },
          {
            name: 'NSF',
            number: '17'
          },
          {
            name: 'OSR',
            number: '39'
          },
          {
            name: 'OSM',
            number: '29'
          },
          {
            name: 'OSF',
            number: '19'
          }
        ]
      }
    },
    colors () {
      return [
        {
          name: 'grey',
          priority: 0,
          hex: '#9E9E9E'
        },
        {
          name: 'green',
          priority: 1,
          hex: '#4CAF50'
        },
        {
          name: 'yellow',
          priority: 2,
          hex: '#FFEE58'
        },
        {
          name: 'red',
          priority: 3,
          hex: '#C62828'
        }
      ]
    },

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    chartOptions () {
      return {
        theme: {
          mode: this.isDark ? 'dark' : 'light'
        },
        xaxis: {
          type: 'datetime'
        },
        stroke: {
          width: 2
        },
        colors: this.seriesColors,
        yaxis: this.yaxis,
        tooltip: {
          shared: true,
          x: {
            format: 'dd-MMM-yyyy HH:mm'
          }
        }
      }
    },

    seriesColors () {
      return [
        '#2196F3',
        '#4CAF50',
        '#673AB7',
        '#F44336',
        '#3F51B5',
        '#CDDC39'
      ]
    },

    yaxis () {
      const yaxis = []
      if (this.showTemperatureSeries) {
        yaxis.push({
          title: {
            text: 'Temperature (°C)'
          },
          seriesName: [...this.temperatureSeriesName],
          opposite: false // Left Y-axis
        })
      }
      if (this.showPressureSeries) {
        yaxis.push({
          title: {
            text: 'Pressure (psi)'
          },
          seriesName: [...this.pressureSeriesName],
          opposite: true // Right Y-axis
        })
      }
      return yaxis
    },

    temperatureSeriesName () {
      return [
        'NSR(°C)', 'NSM(°C)', 'NSF(°C)', 'OSR(°C)', 'OSM(°C)', 'OSF(°C)'
      ]
    },

    pressureSeriesName () {
      return [
        'NSR(psi)', 'NSM(psi)', 'NSF(psi)', 'OSR(psi)', 'OSM(psi)', 'OSF(psi)'
      ]
    },

    temperatureChartOptions () {
      return {
        ...this.chartOptions
        // annotations: {
        //   position: 'back',
        //   yaxis: [
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 90,
        //       y2: 300,
        //       fillColor: 'red'
        //     },
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 75,
        //       y2: 90,
        //       fillColor: 'orange'
        //     },
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 0,
        //       y2: 75,
        //       fillColor: 'green'
        //     }
        //   ]
        // },
      }
    },
    pressureChartOptions () {
      return {
        ...this.chartOptions
        // annotations: {
        //   position: 'back',
        //   yaxis: [
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 120,
        //       y2: 300,
        //       fillColor: 'green'
        //     },
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 105,
        //       y2: 120,
        //       fillColor: 'orange'
        //     },
        //     {
        //       label: {
        //         text: ''
        //       },
        //       y: 0,
        //       y2: 105,
        //       fillColor: 'red'
        //     }
        //   ]
        // }
      }
    },
    temperatureSeries () {
      return this.calculateChart('TyreTemp')
    },
    pressureSeries () {
      return this.calculateChart('TyrePres')
    },
    totalSeries () {
      const series = []
      if (this.showTemperatureSeries) {
        series.push(...this.temperatureSeries)
      }
      if (this.showPressureSeries) {
        series.push(...this.pressureSeries)
      }
      return series
    },

    timeRanges () {
      return [
        {
          label: 'Last 500',
          value: 'last500'
        },
        {
          label: 'Today',
          value: 'day'
        },
        {
          label: 'Week',
          value: 'week'
        },
        {
          label: 'Month',
          value: 'month'
        }
      ]
    }
  },

  watch: {
    currentTab () {
      this.loadingChart = false
      this.updateLoadingChart()
    },
    dialog () {
      this.loadingChart = false
      this.updateLoadingChart()
    },
    unit () {
      this.updateLoadingChart()
    }
  },

  beforeDestroy () {},

  created () {
    this.currentDay = dayjs().format('MMMM DD, YYYY')
    this.updateLoadingChart()
  },

  methods: {
    calculateCategories () {
      const categories = []
      for (const message of this.messages) {
        categories.push(message.receivedAt * 1000)
      }
      return categories
    },
    // @type String
    // The possible types are TyreTemp, and TyrePres
    calculateChart (type) {
      console.log('calculate chart')
      // For in the data tpms messages
      // Create a new array for the data series using the tyres array
      const data = []
      // For each tyre
      for (const tyre of this.tyres) {
        // Create a new object for the data series
        const tyreData = {
          name: `${tyre.name}(${type === 'TyrePres' ? 'psi' : '°C'})`,
          data: []
        }
        // For each message
        for (const message of this.messages) {
          // If the message param is the same as the type and tyre number and not null
          if (message.tpmsParams[`stpms${tyre.number}${type}`]) {
            // Push the message param to the data series
            let value = message.tpmsParams[`stpms${tyre.number}${type}`]
            
            if (type === 'TyreTemp') {
              if (value > 200) {
                value = null
              }
            } else if (type === 'TyrePres') {
              value = (value / 6.895).toFixed(2)
              if (value > 200) {
                value = null
              }
            }
            if (message.tpmsParams[`stpms${tyre.number}WuBatStat`] === 150) {
              value = null
            }
            if (value !== null) {
              tyreData.data.push([message.receivedAt * 1000, value])
            }
          }
        }
        // Add the data series to the data array
        data.push(tyreData)
      }
      console.log(data)
      return data
    },
    updateLoadingChart () {
      const vueObj = this
      setTimeout(() => { vueObj.loadingChart = false }, 1000)
    },
    getRndInteger (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    getTemperature () {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams
        if (tpmsParams[`stpms${this.tyre.number}TyreTemp`]) {
          if (tpmsParams[`stpms${this.tyre.number}TyreTemp`] > 200) {
            return this.$t('units.message.collectingData')
          } else {
            return `${tpmsParams[`stpms${this.tyre.number}TyreTemp`]}°C`
          }
        }
      }
      return this.$t('units.message.collectingData')
    },
    getPressure () {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams
        if (tpmsParams[`stpms${this.tyre.number}TyrePres`]) {
          if ((tpmsParams[`stpms${this.tyre.number}TyrePres`] / 6.895).toFixed(2) > 200) {
            return this.$t('units.message.collectingData')
          } else {
            return (tpmsParams[`stpms${this.tyre.number}TyrePres`] / 6.895).toFixed(2)
          }
        }
      }
      return this.$t('units.message.collectingData')
    },

    getTyreColor () {
      let color = this.colors[0]
      const temperature = this.getTemperature()
      // Validate temperature
      if (temperature !== this.$t('units.message.collectingData')) {
        if (temperature <= 75) {
          color = this.colors[1]
        } else if (temperature > 75 && temperature < 85) {
          color = this.colors[2]
        } else if (temperature >= 85) {
          color = this.colors[3]
        }
      }
      const pressure = this.getPressure()
      // Validate pressure
      if (pressure !== this.$t('units.message.collectingData')) {
        if (pressure >= 115) {
          if (color.priority < this.colors[1].priority) {
            color = this.colors[1]
          }
        } else if (pressure > 100 && pressure < 115) {
          if (color.priority < this.colors[2].priority) {
            color = this.colors[2]
          }
        } else if (pressure <= 100) {
          if (color.priority < this.colors[3].priority) {
            color = this.colors[3]
          }
        }
      }
      return color.hex
    },
    getTpmsMessages (value) {
      this.getEvents(value)
    },
    async getEvents (value) {
      const object = Object.assign({}, this.unit)
      this.loadingChart = true
      await this.$apollo.query({
        query: list,
        variables: {
          apiToken: this.user.apiToken,
          unitId: object.id,
          rangeType: value
        }
      }).then(response => {
        const { status, result } = response.data.tpmsMessages
        switch (status) {
          case 'OK':
            object.events = result
            this.$store.commit('units/updateUnitTpmsMessages', result)
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
            break
        }
      }).catch(err => {
        console.error(err)
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
      }).finally(() => {
        this.updateLoadingChart()
      })
    }
  }
}
</script>

<style lang="scss">
.chart-gradient {
  background: rgb(239, 154, 154);
  background: linear-gradient(
    180deg,
    rgba(239, 154, 154, 1) 0%,
    rgba(255, 224, 130, 1) 50%,
    rgba(165, 214, 167, 1) 100%
  );
}
.chart-gradient-reverse {
  background: rgba(165, 214, 167, 1) 0%;
  background: linear-gradient(
    180deg,
    rgba(165, 214, 167, 1) 0%,
    rgba(255, 224, 130, 1) 50%,
    rgba(239, 154, 154, 1) 100%,
  );
}
.loader{
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 33, 33, 0.46);
}
</style>
